import * as React from "react"
import { Link } from "gatsby"
import Seo from "../components/seo"
import Layout from "../components/layout"

const PageNotFound = (props) => {
  return (
    <Layout>
      <Seo
        title="404: Page Not Found - Kaylar Construction"
        description="Oops! Page not found. Discover our expert general contracting services for remodeling, siding, windows & doors, decks & patios, and interior design."
        location={props.location.pathname}
      />
      <div className="text-black pt-72 text-center pb-56 bg-lndgray px-10">
        <h1 className="text-3xl font-bold pb-5">404: Page Not Found</h1>
        <p className="text-3xl max-w-5xl mx-auto font-medium leading-relaxed">
          Check the web address and try again or return to the Kaylar Construction Home Page
        </p>
        <Link to="/" title="Go to Kaylar Construction Home Page" alt="Go to Kaylar Construction Home Page" className="mt-10">
          <button className=" relative h-10 px-6 text-white transition-colors bg-darkblue rounded-lg border border-darkblue focus:shadow-outline hover:bg-lightblue transition duration-150 ease-in-out">
            <span>Home Page</span>
          </button>
        </Link>
      </div>
    </Layout>
  )
}
export default PageNotFound
